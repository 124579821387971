@font-face {
    font-family: 'SofiaPro';
    src: url('https://transportationshop-fonts.s3.us-west-1.amazonaws.com/sofiapro-light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TerminaTest-Regular';
    src: url('https://transportationshop-fonts.s3.us-west-1.amazonaws.com/TerminaTest-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.navLink {
    color:#9E8356 !important;
    font-family: 'SofiaPro', sans-serif !important;
    padding-left:30px !important;
    padding-right:30px !important;
    text-transform: capitalize !important;
}

.footerParagraph{
    margin: 0px !important;
}
@font-face {
    font-family: 'SofiaPro';
    src: url('https://transportationshop-fonts.s3.us-west-1.amazonaws.com/sofiapro-light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TerminaTest-Regular';
    src: url('https://transportationshop-fonts.s3.us-west-1.amazonaws.com/TerminaTest-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.screensH1{
    background-color:#D25C15;
    color:#FFFAE5;
    text-align: center;
    font-family: 'TerminaTest-Regular', sans-serif;
    padding-top: 20px !important; 
    padding-bottom: 20px !important; 
}

#footerWix{
    padding:1%;
    background-color: #E4D8C2; 
    color:#9E8356;
    font-family: 'SofiaPro', sans-serif;
    font-size: 14px;
}

.container{
    max-width: 100% !important;
    padding: 0px;
}

.pb-3, .py-3 {
    padding-top: 0px !important;   
}
/*footer*/
.col_white_amrc { color:#FFF;}
footer { width:100%; background-color:#E4D8C2; padding:10px 0px 10px 0px ;}
.pt2 { padding-top:40px ; margin-bottom:20px ;}
footer p { font-size:13px; color:#9E8356; padding-bottom:0px; margin-bottom:8px;}
.mb10 { padding-bottom:15px ;}
.footer_ul_amrc { margin:0px ; list-style-type:none ; font-size:14px; padding:0px 0px 10px 0px ; }
.footer_ul_amrc li {padding:0px 0px 5px 0px;}
.footer_ul_amrc li a{ color:#CCC;}
.footer_ul_amrc li a:hover{ color:#fff; text-decoration:none;}
.fleft { float:left;}
.padding-right { padding-right:10px; }

.footer_ul2_amrc {margin:0px; list-style-type:none; padding:0px;}
.footer_ul2_amrc li p { display:table; }
.footer_ul2_amrc li a:hover { text-decoration:none;}
.footer_ul2_amrc li i { margin-top:5px;}

.bottom_border { border-bottom:1px solid #323f45; padding-bottom:20px;}
.foote_bottom_ul_amrc {
	list-style-type:none;
	padding:0px;
	display:table;
	margin-top: 10px;
	margin-right: auto;
	margin-bottom: 10px;
	margin-left: auto;
}
.foote_bottom_ul_amrc li { display:inline;}
.foote_bottom_ul_amrc li a { color:#9E8356; margin:0 12px;}

.social_footer_ul { display:table; margin:15px auto 0 auto; list-style-type:none;  }
.social_footer_ul li { padding-left:20px; padding-top:10px; float:left; }
.social_footer_ul li a { color:#CCC; border:1px solid #CCC; padding:8px;border-radius:50%;}
.social_footer_ul li i {  width:20px; height:20px; text-align:center;}

.responsive-image {
	position: relative;
	max-width: 100%;
  }
  
  .responsive-image__image {
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
  }

  .navbar {
	background-color: #E4D8C2 !important;
  }

  a.nav-link {
	color: #9E8356 !important;
  }

  .card-body {
	background-color: #9E8356 !important;
  }

  @font-face {
    font-family: 'TerminaTest-Regular';
    src: url('https://transportationshop-fonts.s3.us-west-1.amazonaws.com/TerminaTest-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

  .card-title {
    color:#FFFAE5 !important;
    font-family: 'TerminaTest-Regular', sans-serif !important;	
  }

  .card-text{
    color:#FFFAE5 !important;
    font-family: 'TerminaTest-Regular', sans-serif !important;	

  }